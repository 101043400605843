/* You can add global styles to this file, and also import other style files */

*, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

//variables
$baseColor: #191919;
$fontColor: #fff;

body {
  background: $baseColor;
  text-align: center;
  font-family: 'Comfortaa', cursive;
  height: 100%;
}

svg {
  width: 100px;
  margin: 20px;
  display: inline-block;
}

